:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */   
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1300px;
    --fixed-header-height: 80px;
   
    /* Slick */
    --slick-slider-height: 75vh;
    
    /* change settings for headings */
    --text-color: #777;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #222;
    --effect-color: #ccc;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: inherit;
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-li-a-padding: 10px 15px;
    --link-color: inherit;
    --link-hover-color: inherit;
    --map-marker-color: 000;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: 'Lato';
    --alternative-font-family: 'Playfair Display', Helvetica, Arial, sans-serif;  
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fonts/Lato/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 700, 700i, 900, 900i */
@import "/fonts/Playfair Display/all.css"; /* 400, 400i, 700, 700i, 900, 900i */
/*@import "/cssmodules/animate.css";*/

/*--------------------------------------------
General
---------------------------------------------*/
img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

.container-lrg {
    max-width: 90%;
    margin: 0 auto;
}


html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    background: url('/theme/intendit/graphics/bg.jpg') repeat fixed center top;

    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4 {
    font-family: var(--alternative-font-family);
    color: #222;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

.container h1 {
    margin: 2rem 0.8rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {
    background: var(--header-bg-color);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    height: 170px;
}

.headerkitchn {
    position: absolute;
    right: 0;
    max-width: 100px;
    height: auto;
    padding: 1rem;
}

.header-inner {
    background: url('/theme/intendit/graphics/menubg1.png') no-repeat center bottom;
}

header .container {
    padding :0 0.5em;
}

header a {
    text-decoration: none;
}

.logo {
    padding: 0;
    text-align: center;
    margin-top: 10px;
}

.logo img {
    margin-bottom: 50px;
}


.menu li {
    margin:0;
}

.menu li a {
    padding: var(--menu-li-a-padding);
    color: #777; 
    transition: 0.5s ease-in;
    font-family: var(--alternative-font-family);
    font-size: 1.3rem;
}

.menu li a:hover, .menu .active a {
    color: #222; 
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a { 
    margin: 0; 
    background: var(--main-color);
    color: #222;
}

.menu.menu .sub-menu-items li a:hover { 
    background: var(--accent-color); 
    color: #fff;
}


/**** Leftmenu ****/

.submenu ul {
    margin-top: 2.8rem;
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
} 

.submenu ul li a  {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

/*--------------------------------------------------------
Slider startsida
---------------------------------------------------------*/
.slick-slider {
    margin-bottom: 0;
}

.topslider .slick-slide {  
    height: var(--slick-slider-height);
    margin-top: 30px;
}

.topslider .slick-slide {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.topslider .slick-title {
    text-align:center;
    color: var(--text-color);
    /*text-shadow: 1px 1px 2px rgba(0,0,0,0.5);*/
    background: rgba(225,225,225,.8);
    padding: 2rem;
    font-size: 2rem;
    font-family: var(--alternative-font-family);
}

.slick-dots li button:before {
    font-size: 15px !Important;
    top: 20px;
}

.garanti {
    float: right;
    margin-top: -150px;
    position: relative;
    margin-right: 50px 
}

/* Content-startpage | Blocks
---------------------------------------------------------*/

.introimg img {
    float: right;
    max-width: 35%;
    padding-top: 2rem;
    padding-left: 1rem;
}

.offerimg {
    float: right;
}

.block-2  {
    margin: 0 auto;
    margin-top: 50px;
}

.block-2 .container-lrg  {
    background: rgba(255,255,255,.8);
    padding: 2rem;
}

.block-4 {
    background: rgba(255,255,255,.8);
}

/* Content - all
---------------------------------------------------------*/

.fb-block a {
    text-decoration: none;
}

.fb-block h2 {
    padding-left: 2rem;
}

.imagetop {
    min-height: 45vh;
    background: no-repeat center center;
    background-size: cover;
}

.maincontent {
    background: rgba(255,255,255,.8);
}

.logobanner {
    text-align: center;
    width: auto;
}

.priser h1 {
    margin-bottom: 0;
    padding: 1.5rem 1rem;
    margin: 0;
}

.Kakel .galleryimagelist img {
    max-width: 100px;
    height: auto;
}

.Kontakt .maincontent {
    margin-top: 7px;
}

hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

/* Boxar / Zoomboxar
-------------------------------------------------*/

.centerbox .boxwrapper[style] {
    background-size: cover;
    background-position: center center;
    position: relative;
}


.centerbox .boxwrapper:before {

    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 2px solid #fff;   
    content: '';
    opacity: 0;
    transition: opacity .35s,transform .35s;
    transform: scale3d(1.2,1.2,1);
    background: rgba(0,0,0,0.2);
    z-index: 3;
}

.centerbox h2 {
transform: scale3d(1.2,1.2,1);
transition: transform .35s;
}

.centerbox .boxwrapper:hover:before, .boxwrapper:hover h2 {
    opacity: 1;
    transform: scale3d(1,1,1);
}


.centerbox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .35s ease-in 0s;
    z-index: 2;
}

.centerbox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .35s ease-in 0s;
}

.boxes a {
    text-decoration: none;
    color: #fff;
}

.boxes a:hover {
    color: #ccc;
}

.boxbutton, button, input[type=reset], input[type=submit] {
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 0.5rem;
}

.boxwrapper {
    height: 25vh;
    background-size: cover;
    background-position:center;
}

.zoombox .boxwrapper {
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 0px rgba(0,0,0,0.5); 
    z-index: 3;
    font-weight: 400;
    margin-bottom: 1rem;
    background: rgba(225,225,225,.8);
    padding: var(--padding);
}



/* Contact
---------------------------------------------------------*/


.kontakt svg {
    margin-right: 0.5rem;
}


/* listing items
---------------------------------------------------------*/

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}

/* Footer
---------------------------------------------------------*/

footer {
    background: var(--accent-color);
    color: var(--footer-text-color);
}

footer h3 {
    color: #ccc;
}

footer a {
    text-decoration:none;
}

footer svg.fa-icon {
    fill: #ccc;
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
}

footer svg.fa-icon:hover {
    fill: #eee;
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background: var(--accent-color);
    padding: 0.5em 0;
    text-align: right;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
     color: #777;
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

.boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.boltforms-row:nth-of-type(4) {
    width: 100%;
}

.boltforms-row:nth-of-type(5) {
    float: right;
}

.contact-map {
    width: 100%;
}

input[type=email], input[type=number], input[type=search], input[type=text], input[type=tel], input[type=url], input[type=password], textarea {
    width: 100%;
    color: #222;
    padding: 1rem;
}

button[type=submit], input[type=submit] {
    margin:0;
    background: var(--accent-color);
    float: right;
    border: none;
    transition: ease-in 0.5s;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: var(--effect-color);
    color: #fff;
    border: none;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*Not found*/
.pagenotfound {min-height: 100vh; position:relative; text-align:center; font-size:1.1em; }
.pagenotfound .logo img {max-height: 70px; width:auto; max-width: 100%; display:block; }
.pagenotfound ul {list-style-type:none; padding: 0; margin: 0.5em auto; width:100%; max-width: 550px; }
.pagenotfound li a { border-bottom: 1px dashed #d0d0d0; display: inline-block;  padding: 10px 110px; }

/* Media Queries
---------------------------------------------------------*/

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    .intenditfooter {
        text-align: center;
    }
}


@media (max-width: var(--breakpoint-menu)) {
   
    .menu .dropdown ul {
        border: none !important;
        background: var(--accent-color) !important;
        width: 100%;
        padding-left: 30px;
    }
    
    .menu .dropdown ul li a {
       font-size: .8rem;
    }
    
    .menu li, .menu li ul, .menu li:hover ul {
        margin: 0 !Important;
    }
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
        background: var(--accent-color);
        color: #fff;
    }
    
    .menu .active a {
        color: #fff;
        background: var(--effect-color) !important;
    }
}

@media (min-width: var(--breakpoint-s)) {
    /********** FB *************/
    .news-block {
        width: 30%;
        overflow: hidden;
    }
    
    mark {
    background: none;
        color: #fff}

    .news-block-inner {
        overflow-y:scroll;
        height:470px;
    }
}

.news-post p  {
    margin-top:0;
}

.button {
    margin: 0;
    background: var(--effect-color);
    color: white;
    border-radius: 0;
}
